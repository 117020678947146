//  ================================================================================================
//  ? TIP:  It is recommended to use this file for overriding bootstrap variables.
//  ================================================================================================

.btn-primary {
    background-color: #C6E76C !important;
    border-color: #C6E76C !important;
    color: #333 !important;
}

.form-control:focus {
    border-color: #C6E76C !important;
}

.vertical-layout .main-menu .navigation a.active {
    background: linear-gradient(118deg, #C6E76C, rgba(206, 236, 173, 0.7)) !important;
    box-shadow: 0 0 10px 1px rgba(206, 236, 173, 0.7);
}

.page-item.active .page-link {
    background-color: #C6E76C !important;
    color: #333 !important
}

a {
    color: #333 !important
}